import React from "react";
import { Routes, Route } from "react-router-dom";
import CustHome from "../components/customer/CustHome";

export default function BuyerRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<CustHome />} />
    </Routes>
  );
}
