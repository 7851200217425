import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { useDb } from "../../../contexts/DatabaseContext";

export default function AddAccount({ open, close }) {
  const { setStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});

  const handleChange = (e) => {
    setAccountDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    setAccountDetails({});
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setStandardDoc(
        "accounts",
        accountDetails.accountNumber,
        accountDetails
      );
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Account</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Account Name"
          value={accountDetails.accountName}
          name="accountName"
          onChange={handleChange}
        />
        <TextField
          {...textFieldProps}
          label="Account Number"
          value={accountDetails.accountNumber}
          name="accountNumber"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            loading ||
            !accountDetails.accountNumber ||
            !accountDetails.accountName
          }
        >
          Add Account
        </Button>
      </DialogActions>
    </Dialog>
  );
}
