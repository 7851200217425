import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { useAuth } from "./AuthContext";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  function GetCollection(collection, whereArray = null, orderByClause = null) {
    const [data, setData] = useState();
    useEffect(() => {
      let query = db.collection(collection);
      if (whereArray !== null) {
        whereArray.map((whereClause) => {
          query = query.where(...whereClause);
        });
      }
      if (orderByClause !== null) {
        query = query.orderBy(...orderByClause);
      }
      const unsubscribe = query.onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: doc.id,
          label: doc.data().name || doc.id,
        }));
        setData(docs);
      });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  async function getStandardDoc(collection, docId) {
    const doc = await db.collection(collection).doc(docId).get();
    return {
      id: doc.id,
      ...doc.data(),
    };
  }

  async function addStandardDoc(collection, data) {
    const model = await db.collection(collection).add({
      ...data,
      createdAt: new Date(),
      owner: currentUser.uid,
    });
    return model.id;
  }

  async function setStandardDoc(collection, docId, data) {
    return await db
      .collection(collection)
      .doc(docId)
      .set(
        {
          ...data,
          createdAt: new Date(),
          owner: currentUser.uid,
        },
        { merge: true }
      );
  }

  function updateStandardDoc(collection, docId, data) {
    return db
      .collection(collection)
      .doc(docId)
      .update({
        ...data,
        updatedAt: new Date(),
        updatedBy: currentUser.uid,
      });
  }

  const value = {
    GetCollection,
    getStandardDoc,
    updateStandardDoc,
    addStandardDoc,
    setStandardDoc,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
