import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import Item from "./Item";
import { useDb } from "../../../contexts/DatabaseContext";

export default function OrderItems({ orderId }) {
  const { GetCollection } = useDb();
  const items = GetCollection("order_items", [["orderId", "==", orderId]]);

  return (
    <div>
      <Card>
        <CardHeader title="Order Items" />
        <CardContent>
          {items && items.map((item) => <Item key={item.id} item={item} />)}
        </CardContent>
      </Card>
    </div>
  );
}
