import React from "react";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AccountBalance,
  AccountBox,
  ExitToApp,
  History,
  Home,
  LocalShipping,
  People,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function AdminMenu({ classes }) {
  const { logOut } = useAuth();
  return (
    <div>
      <List>
        <Link to="/">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <LocalShipping />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Orders</ListItemText>
          </ListItem>
        </Link>

        <Link to="/users">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <AccountBox />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Users</ListItemText>
          </ListItem>
        </Link>
        <Link to="/accounts">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Accounts</ListItemText>
          </ListItem>
        </Link>
        <ListItem>
          <Button
            startIcon={<ExitToApp />}
            onClick={() => logOut()}
            color="primary"
            variant="contained"
            disableElevation
          >
            Log Out
          </Button>
        </ListItem>
      </List>
    </div>
  );
}
