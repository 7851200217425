import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  // Paste your Firebase config here
  apiKey: "AIzaSyDIQ2n-tNXkipUczb87YjMu3XsiRO60T-w",
  authDomain: "vervewater-6a612.firebaseapp.com",
  projectId: "vervewater-6a612",
  storageBucket: "vervewater-6a612.appspot.com",
  messagingSenderId: "384707311185",
  appId: "1:384707311185:web:84d2ff5c9f8460d8ee6a70",
};

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const functions = app.functions();

export default app;
