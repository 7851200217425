import React from "react";
import { Routes, Route } from "react-router-dom";
import Orders from "../components/admin/orders/Orders";
import OrderDetail from "../components/admin/orders/OrderDetail";
import Users from "../components/admin/customers/Users";
import UserDetail from "../components/admin/customers/UserDetail";
import ArchiveOrders from "../components/admin/orders/ArchiveOrders";
import Accounts from "../components/admin/accounts/Accounts";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Orders />} />
      <Route exact path="/order-detail/:orderId" element={<OrderDetail />} />
      <Route exact path="/order/:orderId" element={<OrderDetail />} />
      <Route exact path="/users" element={<Users />} />
      <Route exact path="/user-detail/:userId" element={<UserDetail />} />
      <Route exact path="/orders-archive" element={<ArchiveOrders />} />
      <Route exact path="/accounts" element={<Accounts />} />
    </Routes>
  );
}
