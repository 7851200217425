import { Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useState } from "react";
import AddAccount from "./AddAccount";
import AccountList from "./AccountList";

export default function Accounts() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Card>
        <CardHeader title="Accounts" />
        <CardActions>
          <Button onClick={() => setOpen(true)} startIcon={<Add />}>
            Add Account
          </Button>
        </CardActions>
        <CardContent>
          <AccountList />
        </CardContent>
      </Card>

      <AddAccount open={open} close={() => setOpen(false)} />
    </div>
  );
}
