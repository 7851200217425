import React, { useState, useEffect } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { LockOpen, Person, Warning } from "@mui/icons-material";
import { functions } from "../../../firebase";

export default function CustomerDetails({ customerId }) {
  const { getStandardDoc, updateStandardDoc } = useDb();
  const [customer, setCustomer] = useState();
  const [snackOpen, setSnackOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadCustomer = async () => {
    console.log("loading customer");
    const c = await getStandardDoc("users", customerId);
    setCustomer(c);
  };

  useEffect(() => {
    const unsubscribe = loadCustomer();
    return unsubscribe;
  }, [customerId, snackOpen]);

  const handleAdminChange = async (e) => {
    const value = e.target.checked;
    await updateStandardDoc("users", customer.id, { admin: value });
    setSnackOpen(true);
    setCustomer({ ...customer, admin: value });
  };

  const toggleBlock = async () => {
    const toggleUserBlock = functions.httpsCallable("toggleUserBlock");
    setLoading(true);
    try {
      await toggleUserBlock({
        uid: customer.id,
        blocked: customer.blocked ? !customer.blocked : true,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return !customer ? (
    <LinearProgress />
  ) : (
    <div>
      <Card>
        <CardHeader
          title={"Customer Details"}
          subheader={customer.companyName}
        />
        <CardContent>
          {customer.blocked && <Alert severity="error">User is blocked!</Alert>}
          <Typography>Account: {customer.account_number} </Typography>
          <Typography>Default Delivery Address: {customer.address} </Typography>
          <Typography>Default Region: {customer.region} </Typography>
          <Typography>Email: {customer.email} </Typography>
          <Typography>
            Default Contact Number: {customer.phone_number}
          </Typography>
          <Typography>Contact Person: {customer.display_name}</Typography>
          <br />
          <Card>
            <CardHeader
              title="Admin Access"
              subheader="Grant admin access to this user. This will allow them to access this admin panel."
              avatar={
                <Avatar>
                  <Person />
                </Avatar>
              }
              action={
                <FormControlLabel
                  onChange={handleAdminChange}
                  control={
                    <Checkbox
                      checked={customer.admin ? customer.admin : false}
                    />
                  }
                  label="Admin"
                />
              }
            />
          </Card>
        </CardContent>
        <CardActions>
          {customer.blocked ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<LockOpen />}
              onClick={toggleBlock}
              disabled={loading}
            >
              Unblock User
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              startIcon={<Warning />}
              onClick={toggleBlock}
              disabled={loading}
            >
              Block User
            </Button>
          )}
        </CardActions>
      </Card>

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        message="Customer details updated!"
      />
    </div>
  );
}
