import React from "react";
import { Routes, Route } from "react-router-dom";
import Forgot from "../components/auth/Forgot";
import SignIn from "../components/auth/SignIn";
import SignUp from "../components/auth/SignUp";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<SignIn />} />
      {/* <Route path="/signup" element={<SignUp />} /> */}
      <Route path="/forgot" element={<Forgot />} />
    </Routes>
  );
}
