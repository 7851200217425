import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { Save, Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import BackLink from "../../shared/BackLink";

export default function ArchiveOrders() {
  const { GetCollection, updateStandardDoc } = useDb();
  const orders = GetCollection(
    "orders",
    [
      ["order_placed", "==", true],
      ["status", "==", "Order Completed"],
    ],
    ["timestamp", "desc"]
  );
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [bulkStatus, setBulkStatus] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleStatusChange = async (order, status) => {
    await updateStandardDoc("orders", order.id, {
      status,
      update_time: new Date(),
    });
    setSnackOpen(true);
  };

  const handleBulkStatusChange = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedRows.map(async (orderId) => {
          await updateStandardDoc("orders", orderId, {
            status: bulkStatus,
            update_time: new Date(),
          });
        })
      );
      setSnackOpen(true);
      setSelectedRows([]);
      setBulkStatus("");
      window.location.reload();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const rows =
    (orders &&
      orders.map((order) => ({
        ...order,
        order_date: order.timestamp.toDate(),
      }))) ||
    [];

  const columns = [
    {
      field: "id",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        return (
          <Link to={`/order-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        );
      },
    },
    { field: "poNumber", headerName: "PO Number", width: 200 },
    {
      field: "order_date",
      headerName: "Order Placed",
      width: 250,
      type: "date",
    },
    { field: "account_no", headerName: "Account", width: 200 },
    { field: "region", headerName: "Region", width: 200 },
    // { field: "status", headerName: "Status", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <FormControl fullWidth>
            <Select
              value={params.row.status}
              onChange={(e) => handleStatusChange(params.row, e.target.value)}
              defaultValue={params.row.status}
            >
              <MenuItem value={"Order Placed"}>Order Placed</MenuItem>
              <MenuItem value={"Order Invoiced"}>Order Invoiced</MenuItem>
              <MenuItem value={"Out on Delivery"}>Out on Delivery</MenuItem>
              <MenuItem value={"Order Completed"}>Order Completed</MenuItem>
              <MenuItem value={"Pending Account Query"}>
                Pending Account Query
              </MenuItem>
              <MenuItem value={"Scheduled for Delivery"}>
                Scheduled for Delivery
              </MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Orders Archive</h1>
      <BackLink />
      {selectedRows.length > 0 && (
        <div style={{ marginBottom: 12 }}>
          <Box sx={{ maxWidth: 250 }}>
            <FormControl fullWidth>
              <InputLabel>Bulk Status Update</InputLabel>
              <Select
                label="Bulk Status Update"
                onChange={(e) => setBulkStatus(e.target.value)}
                value={bulkStatus}
              >
                <MenuItem value={"Order Placed"}>Order Placed</MenuItem>
                <MenuItem value={"Order Invoiced"}>Order Invoiced</MenuItem>
                <MenuItem value={"Out on Delivery"}>Out on Delivery</MenuItem>
                <MenuItem value={"Order Completed"}>Order Completed</MenuItem>
                <MenuItem value={"Pending Account Query"}>
                  Pending Account Query
                </MenuItem>
                <MenuItem value={"Scheduled for Delivery"}>
                  Scheduled for Delivery
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {bulkStatus && (
            <Button
              disabled={loading}
              onClick={handleBulkStatusChange}
              startIcon={<Save />}
            >
              Confirm
            </Button>
          )}
        </div>
      )}
      <RMFDataGrid
        data={rows}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        displayCheckboxes={true}
      />

      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity="success">
          Order Updated!
        </Alert>
      </Snackbar>
    </div>
  );
}
