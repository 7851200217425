import React from 'react'

export default function CustHome() {
    return (
        <div>
            <h1>Home</h1>
            
        </div>
    )
}
