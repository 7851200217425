import { FileUpload, Preview } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import React from "react";

export default function OrderAttachments({ attachments }) {
  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader title="Attachments" />
      <CardContent>
        {attachments.map((attachment, index) => (
          <Card key={attachment}>
            <CardHeader
              avatar={
                <Avatar>
                  <FileUpload />
                </Avatar>
              }
              title={`Attachment ${index + 1}`}
              action={
                <a href={attachment} target="_blank" rel="noreferrer">
                  <IconButton>
                    <Preview />
                  </IconButton>
                </a>
              }
            />
          </Card>
        ))}
      </CardContent>
    </Card>
  );
}
