import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function Users() {
  const { GetCollection } = useDb();
  const users = GetCollection("users");

  const rows =
    (users &&
      users.map((user) => ({
        ...user,
      }))) ||
    [];

  const columns = [
    {
      field: "id",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        return (
          <Link to={`/user-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        );
      },
    },
    { field: "account_number", headerName: "Account", width: 200 },
    { field: "companyName", headerName: "Company", width: 200 },
    { field: "display_name", headerName: "Contact Name", width: 200 },
    { field: "phone", headerName: "Phone No.", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
  ];

  return (
    <div>
      <h1>Users</h1>
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
