import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackLink() {
  const navigate = useNavigate();

  return (
    <div className="back-link">
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
    </div>
  );
}
