import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import OrderEvent from "./OrderEvent";

export default function OrderTimeline({ orderId }) {
  const { GetCollection } = useDb();
  const updates = GetCollection(
    "order_updates",
    [["orderId", "==", orderId]],
    ["created_at", "desc"]
  );

  return (
    <div>
      <Card>
        <CardHeader title="Order Timeline" />
        <CardContent>
          {updates &&
            updates.map((update) => (
              <OrderEvent key={update.id} update={update} />
            ))}
        </CardContent>
      </Card>
    </div>
  );
}
