import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import RMDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function AccountList() {
  const { GetCollection } = useDb();
  const accounts = GetCollection("accounts");

  const rows =
    accounts &&
    accounts.map((account) => ({
      ...account,
    }));

  const columns = [
    { field: "accountName", headerName: "Account Name", width: 200 },
    { field: "accountNumber", headerName: "Account Number", width: 200 },
  ];
  return <div>{accounts && <RMDataGrid data={rows} columns={columns} />}</div>;
}
