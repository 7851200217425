import {
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../shared/BackLink";
import { useDb } from "../../../contexts/DatabaseContext";
import CustomerDetails from "../customers/CustomerDetails";
import OrderDetailCard from "./OrderDetailCard";
import OrderTimeline from "./OrderTimeline";
import { Add } from "@mui/icons-material";
import UpdateOrder from "./UpdateOrder";
import OrderItems from "./OrderItems";
import OrderAttachments from "./OrderAttachments";

export default function OrderDetail() {
  const { orderId } = useParams();
  const { getStandardDoc } = useDb();
  const [order, setOrder] = useState();
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updated, setUpdated] = useState(new Date());

  const loadOrder = async () => {
    const o = await getStandardDoc("orders", orderId);
    setOrder(o);
  };

  useEffect(() => {
    const unsubscribe = loadOrder();
    return unsubscribe;
  }, [orderId, updated]);

  const handleDialogClose = () => {
    setUpdateOpen(false);
    setUpdated(new Date());
  };

  return !order ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink />
        <h1>Order Detail</h1>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <OrderDetailCard order={order} />
            <br />
            <OrderItems orderId={orderId} />
            <br />
            <CustomerDetails customerId={order.customerId} />
          </Grid>
          <Grid item xs={12} md={5}>
            <OrderTimeline orderId={orderId} />
            {order.attachments && order.attachments.length > 0 && (
              <OrderAttachments attachments={order.attachments} />
            )}
          </Grid>
        </Grid>
      </Container>

      <UpdateOrder open={updateOpen} order={order} close={handleDialogClose} />
    </div>
  );
}
