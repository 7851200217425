import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function UpdateOrder({ open, close, order }) {
  const { updateStandardDoc, addStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [data, setData] = useState({
    ...order,
  });

  useEffect(() => {
    return setData(order);
  }, [open]);

  const handleClose = () => {
    setData({});
    close();
  };

  const handleText = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await updateStandardDoc("orders", order.id, {
        ...data,
        update_time: new Date(),
      });
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Order</DialogTitle>
      <DialogContent>
        <TextField
          name="status"
          onChange={handleText}
          {...textFieldProps}
          label="Status"
          value={data.status}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<Save />}
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
