import React from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../shared/BackLink";
import { Container, Grid } from "@mui/material";
import CustomerDetails from "./CustomerDetails";

export default function UserDetail() {
  const { userId } = useParams();

  return (
    <div>
      <Container>
        <BackLink />
        <h1>User Detail</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <CustomerDetails customerId={userId} />
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
      </Container>
    </div>
  );
}
