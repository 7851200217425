import { Info } from "@mui/icons-material";
import { Avatar, Card, CardHeader } from "@mui/material";
import React from "react";

export default function OrderEvent({ update }) {
  return (
    <Card elevation={0}>
      <CardHeader
        title={update.status}
        subheader={update.created_at.toDate().toLocaleString()}
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
      />
    </Card>
  );
}
