import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

export default function OrderDetailCard({ order }) {
  return (
    <Card>
      <CardHeader title="Order Details" subheader={`Order ${order.id}`} />
      <CardContent>
        <Typography>Delivery Address: {order.address}</Typography>
        <Typography>Additional Instructions: {order.instructions}</Typography>
        <Typography>Status: {order.status}</Typography>
      </CardContent>
    </Card>
  );
}
