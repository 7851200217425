import { LocalShipping } from "@mui/icons-material";
import { Avatar, Card, CardHeader } from "@mui/material";
import React from "react";

export default function Item({ item }) {
  return (
    <div>
      <Card elevation={0}>
        <CardHeader
          title={item.product}
          subheader={`${item.volume} | Box Qty: ${item.quantity} | Type: ${item.carbonation}`}
          avatar={
            <Avatar>
              <LocalShipping />
            </Avatar>
          }
        />
      </Card>
    </div>
  );
}
